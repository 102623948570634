import React from 'react';
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import SplitText from 'split-type';

import 'styles/home.css'

const delay = 5000;
gsap.registerPlugin(SplitText);

const Home = () => {

  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === 5 - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  React.useEffect(() => {   
    let tl = gsap.timeline({ delay: 0.5 });
    
    const bandText = new SplitText('.solution', {types:"chars", charClass:"char" });
    const strategiesText = new SplitText('.strategies', {types:"chars", charClass:".strategy" });
   
    tl.from('.char', {
      duration: 0.6, scale:1, autoAlpha:0,  stagger: 0.02,
    }, 0.7);  
    tl.eventCallback("onComplete", function() {
      tl.reverse();
    });
  })

  return (
    <div className='w-full mb-[190px]'>
      <section>
        <div
          dangerouslySetInnerHTML={{
            __html: `<video src='images/clipweb2.mp4' muted loop autoPlay playsInline className='w-full'></video>`,
          }}
        />
      </section>
      <section className='mt-[160px] px-[28px] md:px-[54px] lg:px-[125px]'>
        <p className='font-medium text-[#212121] text-[30px] leading-[1.1em] font-[700] tracking-normal'>
          A <span className='text-c'>C</span><span className='text-r'>R</span><span className='text-e'>E</span><span className='text-a'>A</span><span className='text-t'>T</span><span className='text-c'>I</span><span className='text-r'>V</span><span className='text-e'>E</span> STUDIO THAT MAKES YOUR BUSINESS TAKE OFF
        </p>
        <p className='font-medium text-[#212121] text-[30px] leading-[1.1em] font-[700] tracking-normal mt-[20px] uppercase'>The place where creativity and successful sales <br /> strategies work hand in hand.</p>    
        <p className='font-medium text-[#212121] text-[30px] leading-[1.1em] font-[700] tracking-normal mt-[20px] uppercase'>Get to know the wonders of Artica.</p>
        <Link to='/portfolio' className='font-medium text-[#212121] text-[15px] leading-[1.74em] font-[700] tracking-[0.2em] mt-[17px] underline cursor-pointer'>DISCOVER MORE</Link>       
      </section>
      <section className='px-[28px] md:px-[54px] lg:px-[125px] mt-[100px] flex justify-center'>
        <div className='grid grid-cols-1 md:grid-cols-3 gap-5 max-w-[1140px]'>
          <div>
            <div>
              <img src='images/working.jpg' alt='working' className='w-full' />
            </div>
            <p className='font-medium text-[#212121] text-[19px] leading-[1.15789em] font-[700] tracking-normal mt-[20px]'>HUMAN</p>
            <p className='font-light text-[#7A7A7A] text-[15px] leading-[1.15789em] font-[400] tracking-normal mt-[10px]'>WORKING WITH COMMUNITIES AROUND THE GLOBE</p>
          </div>
          <div>
            <div>
              <img src='images/Lucia_color.jpg' alt='Lucia_color' className='w-full' />
            </div>
            <p className='font-medium text-[#212121] text-[19px] leading-[1.15789em] font-[700] tracking-normal mt-[20px]'>ADAPTABLE</p>
            <p className='font-light text-[#7A7A7A] text-[15px] leading-[1.15789em] font-[400] tracking-normal mt-[10px]'>TO ANY LIFE'S SITUATION</p>
          </div>
          <div>
            <div>
              <img src='images/LEADING.jpg' alt='LEADING' className='w-full' />
            </div>
            <p className='font-medium text-[#212121] text-[19px] leading-[1.15789em] font-[700] tracking-normal mt-[20px]'>LEADING</p>
            <p className='font-light text-[#7A7A7A] text-[15px] leading-[1.15789em] font-[400] tracking-normal mt-[10px]'>ON ALL SECTORS TARGETED</p>
          </div>
        </div>
      </section>
      <section className='px-[28px] md:px-[54px] lg:px-[125px] mt-[100px] md:mt-[135px]'>
        <div className='grid grid-cols-1 md:grid-cols-3 gap-[40px]'>
          <div className='relative flex justify-center items-center flex-col'>
            <div className='relative flex justify-center items-center'>
              <img src='images/small_enterprise.png' alt='small_enterprise' />
              <div className='absolute'>
                <p className='font-medium text-[#fff] text-center text-[35px] leading-[40px] font-[500] tracking-normal'>SMALL ENTERPRISE</p>  
              </div>  
            </div>
          </div>
          <div className='col-span-1 md:col-span-2 relative flex justify-center items-center'>
            <img src='images/large_enterprise.jpg' alt='large_enterprise' />
            <div className='absolute'>
              <p className='font-medium text-[#fff] text-center text-[38px] leading-[40px] font-[500] tracking-normal'>MEDIUM & LARGE ENTERPRISE</p>  
            </div>  
          </div>
        </div>
      </section>
      <section className="slideshow mt-[50px] lg:mt-[150px]">
        <div
          className="slideshowSlider"
          style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
        >
          <div className="slide hidden lg:inline-block">
            <img src='images/Photo1-BIG.jpg' alt='Photo1-BIG.jpg' className='w-full' />
            <div className='w-full h-full relative flex justify-center items-end' >
              <div className='w-[90%] lg:w-[650px] absolute mb-[5vh]' style={{ textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000' }}>
                <p className='uppercase font-medium text-[#fff] text-center text-[16px] leading-[1em] font-[700] tracking-normal'>AEROCAV INTERNATIONAL</p>
                <p className='uppercase whitespace-normal font-medium text-[#fff] text-center text-[26px] lg:text-[32px] leading-[30px] lg:leading-[36px] font-[500] tracking-normal mt-[25px]'>ONE OF THE BIGGEST SHIPPING HUBS CONNECTING THE AMERICAS HAS POSITIONED ITSELF BACK IN THE DIGITAL MARKET AT LAST</p>             
                <Link to='/portfolio'>
                  <p className='uppercase font-medium text-[#fff] text-center text-[14px] leading-[1em] font-400] tracking-normal mt-[30px]'>SHOW ARTICA'S SUCCESS STORIES</p>
                </Link>                
              </div>
            </div>           
          </div>
          <div className="slide hidden lg:inline-block">
            <img src='images/Photo3-BIG.jpg' alt='Photo3-BIG' className='w-full' />
            <div className='w-full h-full relative flex justify-center items-end' >
              <div className='w-[90%] lg:w-[650px] absolute mb-[5vh]' style={{ textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000' }}>
                <p className='uppercase font-medium text-[#fff] text-center text-[16px] leading-[1em] font-[700] tracking-normal'>CYCLING GROUPS</p>
                <p className='uppercase whitespace-normal font-medium text-[#fff] text-center text-[26px] lg:text-[32px] leading-[30px] lg:leading-[36px] font-[500] tracking-normal mt-[25px]'>HIGH CALIBER SPORT TEAMS STRATEGICALLY ATTRACT SPONSORSHIP THROUGH SOCIAL MEDIA</p>             
                <Link to='/portfolio'>
                  <p className='uppercase font-medium text-[#fff] text-center text-[14px] leading-[1em] font-400] tracking-normal mt-[30px]'>SHOW ARTICA'S SUCCESS STORIES</p>
                </Link>                
              </div>
            </div>
          </div>
          <div className="slide hidden lg:inline-block">
            <img src='images/Syway.jpg' alt='Syway' className='w-full' />
            <div className='w-full h-full relative flex justify-center items-end' >
              <div className='w-[90%] lg:w-[650px] absolute mb-[5vh]' style={{ textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000' }}>
                <p className='uppercase font-medium text-[#fff] text-center text-[16px] leading-[1em] font-[700] tracking-normal'>SKYWAY FAMILY CARGO</p>
                <p className='uppercase whitespace-normal font-medium text-[#fff] text-center text-[26px] lg:text-[32px] leading-[30px] lg:leading-[36px] font-[500] tracking-normal mt-[25px]'>FINALLY CONNECTED TO ITS DESIRED TARGET AUDIENCE AND ACHIEVED RECORDS IN ENGAGEMENT AND CLOSED SALES</p>             
                <Link to='/portfolio'>
                  <p className='uppercase font-medium text-[#fff] text-center text-[14px] leading-[1em] font-400] tracking-normal mt-[30px]'>SHOW ARTICA'S SUCCESS STORIES</p>
                </Link>                
              </div>
            </div>
          </div>          
          <div className="slide hidden lg:inline-block">
            <img src='images/Photo4-BIG.jpg' alt='Photo4-BIG' className='w-full' />
            <div className='w-full h-full relative flex justify-center items-end' >
              <div className='w-[90%] lg:w-[650px] absolute mb-[5vh]' style={{ textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000' }}>
                <p className='uppercase font-medium text-[#fff] text-center text-[16px] leading-[1em] font-[700] tracking-normal'>MEXICAN ARTISAN</p>
                <p className='uppercase whitespace-normal font-medium text-[#fff] text-center text-[26px] lg:text-[32px] leading-[30px] lg:leading-[36px] font-[500] tracking-normal mt-[25px]'>LOCAL BRANDS' BUSINESS AND ADVERTISING INFRASTRUCTURES ARE DEFINED</p>             
                <Link to='/portfolio'>
                  <p className='uppercase font-medium text-[#fff] text-center text-[14px] leading-[1em] font-400] tracking-normal mt-[30px]'>SHOW ARTICA'S SUCCESS STORIES</p>
                </Link>                
              </div>
            </div>
          </div>
          <div className="slide hidden lg:inline-block">
            <img src='images/kingdom.jpg' alt='kingdom' className='w-full' />
            <div className='w-full h-full relative flex justify-center items-end' >
              <div className='w-[90%] lg:w-[650px] absolute mb-[5vh]' style={{ textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000' }}>
                <p className='uppercase font-medium text-[#fff] text-center text-[16px] leading-[1em] font-[700] tracking-normal'>THE KINGDOM</p>
                <p className='uppercase whitespace-normal font-medium text-[#fff] text-center text-[26px] lg:text-[32px] leading-[30px] lg:leading-[36px] font-[500] tracking-normal mt-[25px]'>FOSTERING LEADERSHIP, BRAVERY, AND DECISIVE ACTIONS</p>             
                <Link to='/portfolio'>
                  <p className='uppercase font-medium text-[#fff] text-center text-[14px] leading-[1em] font-400] tracking-normal mt-[30px]'>SHOW ARTICA'S SUCCESS STORIES</p>
                </Link>                
              </div>
            </div>
          </div>

          <div className="slide inline-block lg:hidden">
            <img src='images/Photo1-VERTICAL.jpg' alt='Photo1-VERTICAL' className='w-full' />
            <div className='w-full h-full relative flex justify-center items-end' >
              <div className='w-[90%] lg:w-[650px] absolute mb-[5vh]' style={{ textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000' }}>
                <p className='uppercase font-medium text-[#fff] text-center text-[16px] leading-[1em] font-[700] tracking-normal'>AEROCAV INTERNATIONAL</p>
                <p className='uppercase whitespace-normal font-medium text-[#fff] text-center text-[26px] lg:text-[32px] leading-[30px] lg:leading-[36px] font-[500] tracking-normal mt-[25px]'>ONE OF THE BIGGEST SHIPPING HUBS CONNECTING THE AMERICAS HAS POSITIONED ITSELF BACK IN THE DIGITAL MARKET AT LAST</p>             
                <Link to='/portfolio'>
                  <p className='uppercase font-medium text-[#fff] text-center text-[14px] leading-[1em] font-400] tracking-normal mt-[30px]'>SHOW ARTICA'S SUCCESS STORIES</p>
                </Link>                
              </div>
            </div>           
          </div>
          <div className="slide inline-block lg:hidden">
            <img src='images/Photo3-VERTICAL.jpg' alt='Photo3-VERTICAL' className='w-full' />
            <div className='w-full h-full relative flex justify-center items-end' >
              <div className='w-[90%] lg:w-[650px] absolute mb-[5vh]' style={{ textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000' }}>
                <p className='uppercase font-medium text-[#fff] text-center text-[16px] leading-[1em] font-[700] tracking-normal'>CYCLING GROUPS</p>
                <p className='uppercase whitespace-normal font-medium text-[#fff] text-center text-[26px] lg:text-[32px] leading-[30px] lg:leading-[36px] font-[500] tracking-normal mt-[25px]'>HIGH CALIBER SPORT TEAMS STRATEGICALLY ATTRACT SPONSORSHIP THROUGH SOCIAL MEDIA</p>             
                <Link to='/portfolio'>
                  <p className='uppercase font-medium text-[#fff] text-center text-[14px] leading-[1em] font-400] tracking-normal mt-[30px]'>SHOW ARTICA'S SUCCESS STORIES</p>
                </Link>                
              </div>
            </div>
          </div>
          <div className="slide inline-block lg:hidden">
            <img src='images/skyway_mobile.jpg' alt='skyway_mobile' className='w-full' />
            <div className='w-full h-full relative flex justify-center items-end' >
              <div className='w-[90%] lg:w-[650px] absolute mb-[5vh]' style={{ textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000' }}>
                <p className='uppercase font-medium text-[#fff] text-center text-[16px] leading-[1em] font-[700] tracking-normal'>SKYWAY FAMILY CARGO</p>
                <p className='uppercase whitespace-normal font-medium text-[#fff] text-center text-[26px] lg:text-[32px] leading-[30px] lg:leading-[36px] font-[500] tracking-normal mt-[25px]'>FINALLY CONNECTED TO ITS DESIRED TARGET AUDIENCE AND ACHIEVED RECORDS IN ENGAGEMENT AND CLOSED SALES</p>             
                <Link to='/portfolio'>
                  <p className='uppercase font-medium text-[#fff] text-center text-[14px] leading-[1em] font-400] tracking-normal mt-[30px]'>SHOW ARTICA'S SUCCESS STORIES</p>
                </Link>                
              </div>
            </div>
          </div>         
          <div className="slide inline-block lg:hidden">
            <img src='images/Photo4-VERTICAL.jpg' alt='Photo4-VERTICAL' className='w-full' />
            <div className='w-full h-full relative flex justify-center items-end' >
              <div className='w-[90%] lg:w-[650px] absolute mb-[5vh]' style={{ textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000' }}>
                <p className='uppercase font-medium text-[#fff] text-center text-[16px] leading-[1em] font-[700] tracking-normal'>MEXICAN ARTISAN</p>
                <p className='uppercase whitespace-normal font-medium text-[#fff] text-center text-[26px] lg:text-[32px] leading-[30px] lg:leading-[36px] font-[500] tracking-normal mt-[25px]'>LOCAL BRANDS' BUSINESS AND ADVERTISING INFRASTRUCTURES ARE DEFINED</p>             
                <Link to='/portfolio'>
                  <p className='uppercase font-medium text-[#fff] text-center text-[14px] leading-[1em] font-400] tracking-normal mt-[30px]'>SHOW ARTICA'S SUCCESS STORIES</p>
                </Link>                
              </div>
            </div>
          </div>
          <div className="slide inline-block lg:hidden">
            <img src='images/kingdom_mobile.jpg' alt='kingdom_mobile' className='w-full' />
            <div className='w-full h-full relative flex justify-center items-end' >
              <div className='w-[90%] lg:w-[650px] absolute mb-[5vh]' style={{ textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000' }}>
                <p className='uppercase font-medium text-[#fff] text-center text-[16px] leading-[1em] font-[700] tracking-normal'>THE KINGDOM</p>
                <p className='uppercase whitespace-normal font-medium text-[#fff] text-center text-[26px] lg:text-[32px] leading-[30px] lg:leading-[36px] font-[500] tracking-normal mt-[25px]'>FOSTERING LEADERSHIP, BRAVERY, AND DECISIVE ACTIONS</p>             
                <Link to='/portfolio'>
                  <p className='uppercase font-medium text-[#fff] text-center text-[14px] leading-[1em] font-400] tracking-normal mt-[30px]'>SHOW ARTICA'S SUCCESS STORIES</p>
                </Link>                
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='px-[28px] md:px-[54px] lg:px-[125px] mt-0 lg:mt-[100px]'>
        <div className='grid grid-cols-1 lg:grid-cols-2'>
          <img className='block w-full' src='images/edificio.jpg' alt='edificio' />
          <div className='my-[30px] pl-0 lg:pl-[50px] flex flex-col justify-center'>
            <p className='font-medium text-[#000] text-[32px] leading-[36px] font-[600] tracking-[5px]'>
              RUNNING IT ALONE IS NOT ENOUGH. WE'VE HELPED COUNTLESS BUSINESS TO OVERCOME THE OBSTACLES TO SALE MORE.
            </p>
            <p className='font-medium text-[#000] text-[32px] leading-[36px] font-[600] tracking-[5px] mt-[15px]'>ANALYZE, PLAN, DESIGN, DEVELOP AND SALE MORE.</p>
            {/* <p className='font-medium text-[#000] text-[32px] leading-[36px] font-[600] tracking-[5px] mt-[15px] solution' id="text"> */}
              {/* <span className=''>O</span><span className='text-u'>U</span><span className='text-t1'>T</span> <span className='text-o'>O</span><span className='text-f'>F</span> <span className='text-t2'>T</span><span className='text-h'>H</span><span className='text-e'>E</span> <span className='text-b'>B</span><span className='text-o2'>O</span><span className='text-x'>X</span> <span className='dot1'>.</span><span className='dot2'>.</span><span className='dot3'>.</span><span className='text-s1'>S</span><span className='text-o1'>O</span><span className='text-l'>L</span><span className='text-u1'>U</span><span className='text-t3'>T</span><span className='text-i'>I</span><span className='text-o4'>O</span><span className='text-n'>N</span> */}
              {/* OUT OF THE BOX <span className="text-[#FF8202]">...SOLUTION</span> */}
            {/* </p> */}
         
          </div>
        </div>
      </section>
    </div>
  )
}

export default Home