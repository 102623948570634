import React from 'react';
import { Link } from "react-router-dom";

const Navbar = () => {

  const [mobileMenu, setMobileMenu] = React.useState(false);

  return (
    <header className='relative'>
      <div className='hidden lg:grid grid-cols-3 w-full px-[105px] h-[200px]'>
        <div className='flex items-center'>
          <ul>
            <li className='uppercase font-medium pr-[12px] pl-[7px] text-[20px] text-[#212121] leading-[1.4em] font-[600] hover:underline'><Link to="/">Home</Link></li>
            <li className='uppercase font-medium pr-[12px] pl-[7px] text-[20px] text-[#212121] leading-[1.4em] font-[600] hover:underline'><Link to="/portfolio">Portfolio</Link></li>
            <li className='uppercase font-medium pr-[12px] pl-[7px] text-[20px] text-[#212121] leading-[1.4em] font-[600] hover:underline'><Link to="/contact">Contact</Link></li>
          </ul>
        </div>
        <div className='flex justify-center items-center'>
          <Link to='/'> 
            <img src='images/Artica-logo.png' alt='logo' className='w-[400px]' />
          </Link>         
        </div>
        <div></div>
      </div>
      <div className='h-[70px] flex lg:hidden justify-center'>
        <div className='w-[86%] h-full flex justify-between items-center'>
          <Link to='/'> 
            <img src='images/Artica-logo.png' alt='logo' className='w-[150px]' />
          </Link>
          {
            mobileMenu ?
              <p onClick={() => setMobileMenu(false)} className='uppercase font-medium text-[19px] text-[#212121] leading-[1] font-[700] tracking-[.02em] hover:underline'>Close</p>   :
              <p onClick={() => setMobileMenu(true)} className='uppercase font-medium text-[19px] text-[#212121] leading-[1] font-[700] tracking-[.02em] hover:underline'>Menu</p>   
          }  
        </div>
          <nav className={`absolute top-full left-0 w-full bg-[#fff] border-b-[1px] border-solid border-[#212121] ${mobileMenu ? 'border-[#212121]' : 'border-transparent'} flex justify-center z-[11111] items-center transition-[height] duration-500 ease-out overflow-hidden ${mobileMenu ? 'h-[200px]' : 'h-0'}`}>
            <ul className='py-[34px] w-[86%]'>
              <li className='py-[10px] uppercase font-medium pr-[12px] pl-[7px] text-[18px] text-[#212121] leading-[.881em] font-[700] tracking-[.02em] hover:underline'><Link to="/" onClick={() => setMobileMenu(false)} className='leading-[1]'>Home</Link></li>
              <li className='py-[10px] uppercase font-medium pr-[12px] pl-[7px] text-[18px] text-[#212121] leading-[.881em] font-[700] tracking-[.02em] hover:underline'><Link to="/portfolio" onClick={() => setMobileMenu(false)}  className='leading-[1]'>Portfolio</Link></li>
              <li className='py-[10px] uppercase font-medium pr-[12px] pl-[7px] text-[18px] text-[#212121] leading-[.881em] font-[700] tracking-[.02em] hover:underline'><Link to="/contact" onClick={() => setMobileMenu(false)}  className='leading-[1]'>Contact</Link></li>
            </ul>
          </nav>
          
      </div>
      {
        mobileMenu && <div className='w-full h-full bg-black fixed opacity-60 z-[111]'></div>
      }
    </header>
  )
}

export default Navbar