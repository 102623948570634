import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';

type LayoutProps = {
	children: React.ReactNode
}

const Layout = ({ children }: LayoutProps) => {

  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ 
      top: 0,
      behavior: "smooth"
    });
  }, [location.pathname])

  return (
    <div>
      <Navbar />
      {children}
      <Footer />
    </div>
  )
}

export default Layout