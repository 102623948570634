import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-[#000]">
      <div className="py-[100px] px-[20px] lg:px-[100px] text-center">
        <p className="font-medium text-[#ffffff] text-[16px] leading-[18px] font-[500] tracking-[1px]">DO YOU NEED ONE OF OUR SERVICES OR TALK ABOUT HOW TO TRANSFORM YOUR BUSINESS?</p>
        <p className="font-medium text-[#ffffff] text-[30px] lg:text-[50px] leading-[32px] lg:leading-[52px] font-[600] tracking-[1px] mt-[30px]">BE IT UNIQUE SERVICES OR START BOOSTING <br />THE GROWTH YOUR BUSINESS IS CAPABLE OF, <br />WE'RE HERE FOR YOU</p>
        <Link to='/contact'> 
          <p className="font-medium underline text-[#ffffff] text-[16px] leading-[18px] font-[500] tracking-[1px] mt-[30px]">CONTACT ARTICA</p>
        </Link>
        <div className="flex justify-center mt-[100px] mb-[30px]">
          <Link to='/'> 
            <img src="images/Artica-logo-white.png" alt="logo-white" className="w-[300px]"  />
          </Link>
        </div>        
      </div>
      <div className="pb-[10px] text-center">
        <Link to='/legal' className="font-medium text-[#ffffff] text-[16px] leading-[18px] font-[500] tracking-[1px]">LEGAL NOTICE - PRIVACY POLICIES</Link>
      </div>
    </footer>
  )
}

export default Footer