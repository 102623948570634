import React from "react";

const Legal = () => {
  return (
    <section>
      <div className="bg-[#f5f4ed] w-full h-[240px] flex justify-center items-center">
        <div className="w-[86%] lg:w-[960px] xl:w-[1100px] 2xl:w-[1300px]">
          <h1 className="font-medium text-[40px] lg:text-[60px] leading-[1.083em] font-[700] text-[#212121]">LEGAL NOTICE - PRIVACY POLICIES</h1>
        </div>        
      </div>
      <div className="w-full flex justify-center flex-col items-center">
        <div  className="w-[86%] lg:w-[960px] xl:w-[1100px] 2xl:w-[1300px] mb-[50px]">
          <h1 className="font-medium text-center text-[25px] text-[#212121] mt-[40px] leading-[1.3em]">Terms of Service</h1>
          <p className="font-light text-start text-[20px] text-[#212121] mt-[60px] leading-[1.3em]">
            <span className="font-[700]">THE AGREEMENT:</span> The use of this website and services on this website provided
            by ARTICA GROUP LLC (hereinafter referred to as "Company") are subject to the
            following Terms & Conditions (hereinafter the "Agreement"), all parts and sub-parts
            of which are specifically incorporated by reference here. This Agreement shall
            govern the use of all pages on this website (hereinafter collectively referred to as
            "Website") and any services provided by or on this Website ("Services").
          </p>
          <p className="font-medium text-start text-[25px] font-[900] text-[#212121] mt-[30px] leading-[1.3em]">1) DEFINITIONS</p>
          <p className="font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">The parties referred to in this Agreement shall be defined as follows:</p>
          <div className="pl-0 mg:pl-[30px]">
            <p className="font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
              a) Company, Us, We: The Company, as the creator, operator, and publisher of
              the Website, makes the Website, and certain Services on it, available to users.
              Company, Us, We, Our, Ours and other first-person pronouns will refer to the
              Company, as well as all employees and affiliates of the Company.
            </p>
            <p className="font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
              b) You, the User, the Client: You, as the user of the Website, will be referred to
              throughout this Agreement with second-person pronouns such as You, Your,
              Yours, or as User or Client.
            </p>
            <p className="font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
              c) Parties: Collectively, the parties to this Agreement (the Company and You)
              will be referred to as Parties.
            </p>
          </div>
          <p className="font-medium text-start text-[25px] font-[900] text-[#212121] mt-[30px] leading-[1.3em]">2) ASSENT & ACCEPTANCE</p>
          <p className="font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
            By using the Website, You warrant that You have read and reviewed this
            Agreement and that You agree to be bound by it. If You do not agree to be bound by
            this Agreement, please leave the Website immediately. The Company only agrees to
            provide use of this Website and Services to You if You assent to this Agreement.
          </p>
          <p className="font-medium text-start text-[25px] font-[900] text-[#212121] mt-[30px] leading-[1.3em]">3) LICENSE TO USE WEBSITE</p>
          <p className="font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
            The Company may provide You with certain information as a result of Your use of
            the Website or Services. Such information may include, but is not limited to,
            documentation, data, or information developed by the Company, and other materials
            which may assist in Your use of the Website or Services ("Company Materials").
            Subject to this Agreement, the Company grants You a non-exclusive, limited, nontransferable and revocable license to use the Company Materials solely in
            connection with Your use of the Website and Services. The Company Materials may2/7
            not be used for any other purpose, and this license terminates upon Your cessation
            of use of the Website or Services or at the termination of this Agreement.
          </p>
          <p className="font-medium text-start text-[25px] font-[900] text-[#212121] mt-[30px] leading-[1.3em]">4) INTELLECTUAL PROPERTY</p>
          <p className="font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
            You agree that the Website and all Services provided by the Company are the
            property of the Company, including all copyrights, trademarks, trade secrets,
            patents, and other intellectual property ("Company IP"). You agree that the
            Company owns all right, title and interest in and to the Company IP and that You
            will not use the Company IP for any unlawful or infringing purpose. You agree not
            to reproduce or distribute the Company IP in any way, including electronically or
            via registration of any new trademarks, trade names, service marks or Uniform
            Resource Locators (URLs), without express written permission from the Company.
          </p>
          <p className="font-medium text-start text-[25px] font-[900] text-[#212121] mt-[30px] leading-[1.3em]">5) USER OBLIGATIONS</p>
          <p className="font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
            As a user of the Website or Services, You may be asked to register with Us. When
            You do so, You will choose a user identifier, which may be Your email address or
            another term, as well as a password. You may also provide personal information,
            including, but not limited to, Your name. You are responsible for ensuring the
            accuracy of this information. This identifying information will enable You to use the
            Website and Services. You must not share such identifying information with any
            third party, and if You discover that Your identifying information has been
            compromised, You agree to notify Us immediately in writing. Email notification will
            suffice. You are responsible for maintaining the safety and security of Your
            identifying information as well as keeping Us apprised of any changes to Your
            identifying information. Providing false or inaccurate information, or using the
            Website or Services to further fraud or unlawful activity is grounds for immediate
            termination of this Agreement.
          </p>
          <p className="font-medium text-start text-[25px] font-[900] text-[#212121] mt-[30px] leading-[1.3em]">6) ACCEPTABLE USE</p>
          <div className="font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
            You agree not to use the Website or Services for any unlawful purpose or any
            purpose prohibited under this clause. You agree not to use the Website or Services
            in any way that could damage the Website, Services, or general business of the
            Company.
              <p className="pl-0 md:pl-[30px] font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
                a) You further agree not to use the Website or Services:
              </p>
              <div className="pl-0 md:pl-[60px] font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
                <p className="font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
                  I) To harass, abuse, or threaten others or otherwise violate any person's legal rights;
                </p>
                <p className="font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
                  II) To violate any intellectual property rights of the Company or any third party;
                </p>
                <p className="font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
                  III) To upload or otherwise disseminate any computer viruses or other software that may damage the property of another;
                </p>
                <p className="font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
                  IV) To perpetrate any fraud;
                </p>
                <p className="font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
                  V) To engage in or create any unlawful gambling, sweepstakes, or pyramid scheme;
                </p>
                <p className="font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
                  VI) To publish or distribute any obscene or defamatory material;
                </p>
                <p className="font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
                  VII) To publish or distribute any material that incites violence, hate, or discrimination towards any group;
                </p>
                <p className="font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
                  VIII) To unlawfully gather information about others.
                </p>
              </div>
          </div>
          <p className="font-medium text-start text-[25px] font-[900] text-[#212121] mt-[30px] leading-[1.3em]">7) AFFILIATE MARKETING & ADVERTISING</p>
          <p className="font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
            The Company, through the Website and Services, may engage in affiliate marketing
            whereby the Company receives a commission on or percentage of the sale of goods or
            services on or through the Website. The Company may also accept advertising and
            sponsorships from commercial businesses or receive other forms of advertising
            compensation. This disclosure is intended to comply with the US Federal Trade
            Commission Rules on marketing and advertising, as well as any other legal
            requirements which may apply.
          </p>
          <p className="font-medium text-start text-[25px] font-[900] text-[#212121] mt-[30px] leading-[1.3em]">8) PRIVACY INFORMATION</p>
          <div className="font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
            Through Your Use of the Website and Services, You may provide Us with certain
            information. By using the Website or the Services, You authorize the Company to
            use Your information in the United States and any other country where We may
            operate.
            <p className="pl-0 md:pl-[30px] font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
              a) Information We May Collect or Receive: When You register for an account,
              You provide Us with a valid email address and may provide Us with additional
              information, such as Your name or billing information. Depending on how You
              use Our Website or Services, We may also receive information from external
              applications that You use to access Our Website, or We may receive information
              through various web technologies, such as cookies, log files, clear gifs, web
              beacons or others.
            </p>
            <p className="pl-0 md:pl-[30px] font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
              b) How We Use Information: We use the information gathered from You to
              ensure Your continued good experience on Our website, including through email4/7
              communication. We may also track certain aspects of the passive information
              received to improve Our marketing and analytics, and for this, We may work
              with third-party providers.
            </p>
            <p className="pl-0 md:pl-[30px] font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
              c) How You Can Protect Your Information: If You would like to disable Our
              access to any passive information We receive from the use of various
              technologies, You may choose to disable cookies in Your web browser. Please be
              aware that the Company will still receive information about You that You have
              provided, such as Your email address.
            </p>
          </div>
          <p className="font-medium text-start text-[25px] font-[900] text-[#212121] mt-[30px] leading-[1.3em]">9) SERVICES</p>
          <p className="font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
            The Company may sell services or allow third parties to sell services on the Website.
            The Company undertakes to be as accurate as possible with all information
            regarding the services, including descriptions and prices. However, the Company
            does not guarantee the accuracy or reliability of any service information, and You
            acknowledge and agree that You purchase such products at Your own risk.
          </p>
          <p className="font-medium text-start text-[25px] font-[900] text-[#212121] mt-[30px] leading-[1.3em]">10) DATA LOSS</p>
          <p className="font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
            The Company does not accept responsibility for the security of Your account or
            content. You agree that Your use of the Website or Services is at Your own risk.
          </p>
          <p className="font-medium text-start text-[25px] font-[900] text-[#212121] mt-[30px] leading-[1.3em]">11) INDEMNIFICATION</p>
          <p className="font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
            You agree to defend and indemnify the Company and any of its affiliates (if
            applicable) and hold Us harmless against any and all legal claims and demands,
            including reasonable attorney's fees, which may arise from or relate to Your use or
            misuse of the Website or Services, Your breach of this Agreement, or Your conduct
            or actions. You agree that the Company shall be able to select its own legal counsel
            and may participate in its own defense, if the Company wishes.
          </p>
          <p className="font-medium text-start text-[25px] font-[900] text-[#212121] mt-[30px] leading-[1.3em]">12) SPAM POLICY</p>
          <p className="font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
            You are strictly prohibited from using the Website or any of the Company's Services
            for illegal spam activities, including gathering email addresses and personal
            information from others or sending any mass commercial emails.
          </p>
          <p className="font-medium text-start text-[25px] font-[900] text-[#212121] mt-[30px] leading-[1.3em]">13) THIRD-PARTY LINKS & CONTENT</p>
          <p className="font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
            The Company may occasionally post links to third party websites or other services.
            You agree that the Company is not responsible or liable for any loss or damage5/7
            caused as a result of Your use of any third party services linked to from Our
            Website.
          </p>
          <p className="font-medium text-start text-[25px] font-[900] text-[#212121] mt-[30px] leading-[1.3em]">14) MODIFICATION & VARIATION</p>
          <p className="font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
            The Company may, from time to time and at any time without notice to You, modify
            this Agreement. You agree that the Company has the right to modify this
            Agreement or revise anything contained herein. You further agree that all
            modifications to this Agreement are in full force and effect immediately upon
            posting on the Website and that modifications or variations will replace any prior
            version of this Agreement, unless prior versions are specifically referred to or
            incorporated into the latest modification or variation of this Agreement.
          </p>
          <p className="font-medium text-start text-[25px] font-[900] text-[#212121] mt-[30px] leading-[1.3em]">15) ENTIRE AGREEMENT</p>
          <p className="font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
            This Agreement constitutes the entire understanding between the Parties with
            respect to any and all use of this Website. This Agreement supersedes and replaces
            all prior or contemporaneous agreements or understandings, written or oral,
            regarding the use of this Website.
          </p>
          <p className="font-medium text-start text-[25px] font-[900] text-[#212121] mt-[30px] leading-[1.3em]">16) SERVICE INTERRUPTIONS</p>
          <p className="font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
            The Company may need to interrupt Your access to the Website to perform
            maintenance or emergency services on a scheduled or unscheduled basis. You agree
            that Your access to the Website may be affected by unanticipated or unscheduled
            downtime, for any reason, but that the Company shall have no liability for any
            damage or loss caused as a result of such downtime.
          </p>
          <p className="font-medium text-start text-[25px] font-[900] text-[#212121] mt-[30px] leading-[1.3em]">17) TERM, TERMINATION & SUSPENSION</p>
          <p className="font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
            The Company may terminate this Agreement with You at any time for any reason,
            with or without cause. The Company specifically reserves the right to terminate this
            Agreement if You violate any of the terms outlined herein, including, but not limited
            to, violating the intellectual property rights of the Company or a third party, failing
            to comply with applicable laws or other legal obligations, and/or publishing or
            distributing illegal material. If You have registered for an account with Us, You may
            also terminate this Agreement at any time by contacting Us and requesting
            termination. At the termination of this Agreement, any provisions that would be
            expected to survive termination by their nature shall remain in full force and effect.
          </p>
          <p className="font-medium text-start text-[25px] font-[900] text-[#212121] mt-[30px] leading-[1.3em]">18) NO WARRANTIES</p>
          <p className="font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
            You agree that Your use of the Website and Services is at Your sole and exclusive
            risk and that any Services provided by Us are on an "As Is" basis. The Company
            hereby expressly disclaims any and all express or implied warranties of any kind,
            including, but not limited to the implied warranty of fitness for a particular purpose
            and the implied warranty of merchantability. The Company makes no warranties
            that the Website or Services will meet Your needs or that the Website or Services
            will be uninterrupted, error-free, or secure. The Company also makes no warranties
            as to the reliability or accuracy of any information on the Website or obtained
            through the Services. You agree that any damage that may occur to You, through
            Your computer system, or as a result of loss of Your data from Your use of the
            Website or Services is Your sole responsibility and that the Company is not liable for
            any such damage or loss.
          </p>
          <p className="font-medium text-start text-[25px] font-[900] text-[#212121] mt-[30px] leading-[1.3em]">19) LIMITATION ON LIABILITY</p>
          <p className="font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
            The Company is not liable for any damages that may occur to You as a result of
            Your use of the Website or Services, to the fullest extent permitted by law. The
            maximum liability of the Company arising from or relating to this Agreement is
            limited to the amount You paid to the Company in the last six (6) months. This
            section applies to any and all claims by You, including, but not limited to, lost profits
            or revenues, consequential or punitive damages, negligence, strict liability, fraud, or
            torts of any kind.
          </p>
          <p className="font-medium text-start text-[25px] font-[900] text-[#212121] mt-[30px] leading-[1.3em]">20) GENERAL PROVISIONS:</p>
          <div className="pl-0 md:pl-[30px]">
            <p className="font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
              <span className="font-[700]">a) LANGUAGE:</span> All communications made or notices given pursuant to this Agreement shall be in the English language.
            </p>
            <p className="font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
              <span className="font-[700]">b) JURISDICTION, VENUE & CHOICE OF LAW:</span> Through Your use of the
              Website or Services, You agree that the laws applicable at the headquarters of
              Company shall govern any matter or dispute relating to or arising out of this
              Agreement, as well as any dispute of any kind that may arise between You and
              the Company, with the exception of its conflict of law provisions
            </p>
            <p className="font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
              <span className="font-[700]">c) ASSIGNMENT:</span> This Agreement, or the rights granted hereunder, may not
              be assigned, sold, leased or otherwise transferred in whole or part by You.
              Should this Agreement, or the rights granted hereunder, by assigned, sold,
              leased or otherwise transferred by the Company, the rights and liabilities of the
              Company will bind and inure to any assignees, administrators, successors, and
              executors.
            </p>
            <p className="font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
              <span className="fon-[700]">d) SEVERABILITY:</span> If any part or sub-part of this Agreement is held invalid or
              unenforceable by a court of law or competent arbitrator, the remaining parts7/7
              and sub-parts will be enforced to the maximum extent possible. In such
              condition, the remainder of this Agreement shall continue in full force.
            </p>
            <p className="font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
              <span className="font-[700]">e) NO WAIVER:</span> In the event that We fail to enforce any provision of this
              Agreement, this shall not constitute a waiver of any future enforcement of that
              provision or of any other provision. Waiver of any part or sub-part of this
              Agreement will not constitute a waiver of any other part or sub-part.
            </p>
            <p className="font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
              <span className="font-[700]">f) HEADINGS FOR CONVENIENCE ONLY:</span> Headings of parts and sub-parts
              under this Agreement are for convenience and organization, only. Headings
              shall not affect the meaning of any provisions of this Agreement.
            </p>
            <p className="font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
              <span className="font-[700]">g) NO AGENCY, PARTNERSHIP OR JOINT VENTURE:</span> No agency,
              partnership, or joint venture has been created between the Parties as a result of
              this Agreement. No Party has any authority to bind the other to third parties.
            </p>
            <p className="font-light text-start text-[20px] text-[#212121] mt-[10px] leading-[1.3em]">
              <span className="font-[700]">h) FORCE MAJEURE:</span> The Company is not liable for any failure to perform
              due to causes beyond its reasonable control including, but not limited to, acts of
              God, acts of civil authorities, acts of military authorities, riots, embargoes, acts
              of nature and natural disasters, and other acts which may be due to unforeseen
              circumstances.
            </p>
          </div>
        </div>  
      </div>
           
    </section>
  )
}

export default Legal