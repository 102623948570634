import React, { useRef } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 3000, min: 1368 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1368, min: 925 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 925, min: 0 },
    items: 1
  }
};

const responsive_side = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1368 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1368, min: 925 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 925, min: 0 },
    items: 1
  }
};

const Portfolio = () => {
  const ref = useRef(null);

  return (
    <section>
      <div className="bg-white rounded-b-[20px] relative z-20">
        <div className="flex justify-center flex-col items-center relative">
          <h1 className="font-medium text-black text-[50px] lg:text-[90px] xl:text-[130px] font-[400]">Portfolio</h1>
          <p className="w-[90%] lg:w-[800px] xl:w-[1005px] text-center font-light text-[#646464] leading-[100%] mt-[10px] text-[20px] lg:text-[30px] font-[400]">Great moments last forever—and are always captured, too.</p>
          <img src='images/aro_naranja.svg' className="absolute left-0 top-0 hidden lg:block w-[140px] xl:w-[172px]" alt="aro_naranja" />
        </div>
        <div className="mt-[150px] hidden lg:flex justify-end">
          <img src="images/estrategies.svg" alt='estrategies' className="w-[400px] xl:w-[500px] 2xl:w-[700px]" />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-0 lg:gap-[50px] xl:gap-[100px] mt-[30px] px-[25px] lg:px-[50px] xl:px-[100px] 2xl:px-[200px] relative items-center">
          <div className="mt-[40px]">
            <h1 className="text-black text-[30px] lg:text-[50px] xl:text-[70px] font-[400] leading-[30px] lg:leading-[50px] xl:leading-[70px] font-medium">BY YOUR SIDE</h1>
            <p className="text-[#646464] text-[15px] lg:text-[22px] xl:text-[30px] font-[400] leading-[15px] lg:leading-[22px] xl:leading-[40px] font-light w-[90%] lg:w-[300px] xl:w-[530px] mt-[30px] lg:mt-[50px] xl:mt-[80px]">Artica provides identity and professionalism to your career or simply save those special moments for posterity</p>
          </div>
          <div className="relative mt-10 lg:mt-0">
            <div className="flex justify-end"><img src="images/pre-production.svg" className="w-[133px] mr-[40px] lg:mr-[60px]" alt="pre-production" /></div>            
            <div className="rounded-[20px] overflow-hidden mt-[15px]">
              <Carousel     
                swipeable={true}
                draggable={true}
                partialVisbile
                showDots={false}
                responsive={responsive_side}
                ssr={true} 
                infinite={false}        
                keyBoardControl={true}
                transitionDuration={500}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-side"
              >
                <img src="images/Clinica.jpg" alt="Clinica" className="w-full" />
                <img src="images/rosangela.jpg" alt="rosangela" className="w-full" />
                <img src="images/thekingdom.jpg" alt="thekingdom" className="w-full" />
                <img src="images/photoshot.jpg" alt="photoshot" className="w-full" />
                <img src="images/Office_miami.jpg" alt="Office_miami" className="w-full" />
              </Carousel>
            </div>            
            <img src="images/pre-production.svg" className="absolute top-[86px] left-[-20px] w-[133px]" alt="pre-production" />
          </div>
          <div className="absolute right-0 hidden lg:block">
            <img src="images/aro_naranja_right.svg" className="w-[100px] xl:w-[172px]" alt="aro_naranja_right" />
          </div>
        </div>
        <div className="lg:flex justify-center hidden">
          <img src="images/vector_up.svg" className="w-[574px]" alt="vector_up" />
        </div>
        <div className="hidden lg:block">
          <img src='images/creativity.svg' className="mt-[41px] w-[400px] xl:w-[500px] 2xl:w-[650px]" alt="creativity" />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-0 lg:gap-[50px] xl:gap-[100px] relative mt-[55px] px-[18px] lg:px-[36px] xl:px-[72px] 2xl:px-[144px] items-center">
          <div className="mt-[25px] block lg:hidden">
            <h1 className="text-black text-[30px] lg:text-[50px] xl:text-[70px] font-[400] leading-[30px] lg:leading-[50px] xl:leading-[70px] font-medium">Modern & Minimalist</h1>
            <p className="text-[#646464] text-[15px] lg:text-[22px] xl:text-[30px] font-[400] leading-[15px] lg:leading-[22px] xl:leading-[40px] font-light w-[90%] lg:w-[300px] xl:w-[530px] mt-[20px] lg:mt-[30px] xl:mt-[60px]">Conveying the right message is key, Artica makes it hassle-free</p>
          </div>
          <div className="relative mt-[30px] lg:mt-0">
            <div className="flex justify-end">
              <img src="images/production.svg" className="w-[133px] mr-[40px] lg:mr-[80px]" alt="production" />
            </div>          
            <div className="rounded-[20px] overflow-hidden mt-[15px]">
              <Carousel     
                swipeable={true}
                draggable={true}
                partialVisbile
                showDots={false}
                responsive={responsive_side}
                ssr={true} 
                infinite={false}        
                keyBoardControl={true}
                transitionDuration={500}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-side"
              >
                <img src="images/office-1.jpg" alt="office-1" className="w-full" />
                <img src="images/office-2.jpg" alt="office-2" className="w-full" />
                <img src="images/office-3.jpg" alt="office-3" className="w-full" />
                <img src="images/office-6.jpg" alt="office-6" className="w-full" />
              </Carousel>
            </div>  
            <img src="images/production.svg" className="absolute top-[86px] left-[-20px] w-[133px]" alt="production" />
          </div>
          <div className="mt-[25px] hidden lg:block">
            <h1 className="text-black text-[30px] lg:text-[50px] xl:text-[70px] font-[400] leading-[30px] lg:leading-[50px] xl:leading-[70px] font-medium">Modern & Minimalist</h1>
            <p className="text-[#646464] text-[15px] lg:text-[22px] xl:text-[30px] font-[400] leading-[15px] lg:leading-[22px] xl:leading-[40px] font-light w-[90%] lg:w-[300px] xl:w-[530px] mt-[20px] lg:mt-[30px] xl:mt-[60px]">Conveying the right message is key, Artica makes it hassle-free</p>
          </div>
        </div>
        <div className="mt-[90px] lg:flex justify-center hidden">
          <img src="images/vector_down.svg" alt="vector_down" className="w-[574px]" />
        </div>
        <div className="mt-[40px] hidden lg:flex justify-end">
          <img src="images/results.svg" alt='results' className="w-[400px] xl:w-[500px] 2xl:w-[700px]" />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-0 lg:gap-[50px] xl:gap-[100px] mt-[30px] px-[25px] lg:px-[50px] xl:px-[100px] 2xl:px-[200px] relative items-center">
          <div className="mt-[40px]">
            <h1 className="text-black text-[30px] lg:text-[50px] xl:text-[70px] font-[400] leading-[30px] lg:leading-[50px] xl:leading-[70px] font-medium">Business focused</h1>
            <p className="text-[#646464] text-[15px] lg:text-[22px] xl:text-[30px] font-[400] leading-[15px] lg:leading-[22px] xl:leading-[40px] font-light w-[90%] lg:w-[300px] xl:w-[530px] mt-[30px] lg:mt-[50px] xl:mt-[80px]">Great content creation attracts lasting customers, so does Artica</p>
          </div>
          <div className="relative mt-10 lg:mt-0">
            <div className="flex justify-end">
              <img src="images/production.svg" className="w-[133px] mr-[40px] lg:mr-[60px]" alt="production" />
            </div>
            <div className="rounded-[20px] overflow-hidden mt-[15px]">
              <Carousel     
                swipeable={true}
                draggable={true}
                partialVisbile
                showDots={false}
                responsive={responsive_side}
                ssr={true} 
                infinite={false}        
                keyBoardControl={true}
                transitionDuration={500}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-side"
              >
                <img src="images/vino.jpg" alt="vino" className="w-full" />
                <img src="images/zapatillas.jpg" alt="zapatillas" className="w-full" />
                <img src="images/pizza.jpg" alt="pizza" className="w-full" />
                <img src="images/libreta.jpg" alt="libreta" className="w-full" />
              </Carousel>
            </div>  
            <img src="images/production.svg" className="absolute top-[86px] left-[-20px] w-[133px]" alt="production" />
          </div>
        </div>
        <div className="hidden lg:flex justify-center">
          <img src="images/vector_up.svg" className="w-[574px]" alt="vector_up" />
        </div>
        <div className="hidden lg:block">
          <img src='images/sales.svg' className="mt-[41px] w-[400px] xl:w-[500px] 2xl:w-[650px]" alt="sales" />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-0 lg:gap-[50px] xl:gap-[100px] relative mt-[55px] px-[18px] lg:px-[36px] xl:px-[72px] 2xl:px-[144px] items-center">
          <div className="mt-[25px] block lg:hidden">
            <h1 className="text-black text-[30px] lg:text-[50px] xl:text-[70px] font-[400] leading-[30px] lg:leading-[50px] xl:leading-[70px] font-medium">Reaching above and beyond</h1>
            <p className="text-[#646464] text-[15px] lg:text-[22px] xl:text-[30px] font-[400] leading-[15px] lg:leading-[22px] xl:leading-[40px] font-light w-[90%] lg:w-[300px] xl:w-[530px] mt-[20px] lg:mt-[30px] xl:mt-[60px]">All angles matter, here at Artica the sky is the limit, literally</p>
          </div>
          <div className="relative mt-[30px] lg:mt-0">
            <div className="flex justify-end">
              <img src="images/post-production.svg" className="w-[133px] mr-[40px] lg:mr-[80px]" alt="post-production" />
            </div>          
            <div className="rounded-[20px] overflow-hidden mt-[15px]">
              <Carousel     
                swipeable={true}
                draggable={true}
                partialVisbile
                showDots={false}
                responsive={responsive_side}
                ssr={true} 
                infinite={false}        
                keyBoardControl={true}
                transitionDuration={500}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-side"
              >
                <img src="images/Avila.jpg" alt="Avila" className="w-full" />
                <img src="images/salinas.jpg" alt="salinas" className="w-full" />
                <img src="images/los llanos.jpg" alt="los llanos" className="w-full" />
                <img src="images/calabozo.jpg" alt="calabozo" className="w-full" />
                <img src="images/boca de aroa.jpg" alt="boca de aroa" className="w-full" />
              </Carousel>
            </div> 
            <img src="images/post-production.svg" className="absolute top-[86px] left-[-20px] w-[133px]" alt="post-production" />
          </div>
          <div className="mt-[25px] hidden lg:block">
            <h1 className="text-black text-[30px] lg:text-[50px] xl:text-[70px] font-[400] leading-[30px] lg:leading-[50px] xl:leading-[70px] font-medium">Reaching above and beyond</h1>
            <p className="text-[#646464] text-[15px] lg:text-[22px] xl:text-[30px] font-[400] leading-[15px] lg:leading-[22px] xl:leading-[40px] font-light w-[90%] lg:w-[300px] xl:w-[530px] mt-[20px] lg:mt-[30px] xl:mt-[60px]">All angles matter, here at Artica the sky is the limit, literally</p>
          </div>
        </div>
        <div className="flex justify-start flex-col items-center relative mt-[80px] lg:mt-[220px] pb-[100px] lg:pb-[200px]">
          <h1 className="font-medium text-black text-center text-[50px] lg:text-[90px] xl:text-[130px] leading-[110px] font-[400]">Our Customers</h1>
          <div className="flex justify-center flex-wrap gap-[20px] lg:gap-[60px] mt-[20px] lg:mt-[60px]">
            <img src='images/Aerocav.png' alt="Aerocav" className="w-[60px] lg:w-[130px] xl:w-[150px]" />
            <img src='images/Delta1.png' alt="Delta1" className="w-[60px] lg:w-[130px] xl:w-[150px]" />
            <img src='images/Otrade.png' alt="Otrade" className="w-[60px] lg:w-[130px] xl:w-[150px]" />
            <img src='images/Grupo Ferreira.png' alt="Grupo Ferreira" className="w-[60px] lg:w-[130px] xl:w-[150px]" />
            <img src='images/Chiguagua.png' alt="Chiguagua" className="w-[60px] lg:w-[130px] xl:w-[150px]" />
            <img src='images/360.png' alt="360" className="w-[60px] lg:w-[130px] xl:w-[150px]" />
            <img src='images/Skyway.png' alt="Skyway" className="w-[60px] lg:w-[130px] xl:w-[150px]" />
          </div>
          <img src="images/aro_naranja_green.svg" alt="aro_naranja_green" className="absolute left-0 hidden lg:block w-[10%]" />
        </div>
      </div>
      <div className="bg-[#171717] mt-[-20px] relative z-10 pt-[100px] pb-[100px] lg:pb-[300px]">
        <div className="flex justify-center flex-col items-center relative">
          <h1 className="text-center font-medium text-white text-[50px] lg:text-[90px] xl:text-[130px] font-[400]">Featured Videos</h1>
          <p className="w-[90%] lg:w-[800px] xl:w-[1005px] text-center font-light text-white leading-[100%] mt-[10px] text-[20px] lg:text-[30px] font-[400]">Our most recent, dedicated audiovisual work</p>
        </div>
        <div className="px-[18px] lg:px-[36px] xl:px-[72px] 2xl:px-[90px] mt-[120px] gap-[30px]">
          <Carousel        
            ref={ref}
            swipeable={true}
            draggable={true}
            partialVisbile
            showDots={false}
            responsive={responsive}
            ssr={true} 
            infinite={false}        
            keyBoardControl={true}
            transitionDuration={500}
            containerClass="carousel-container"
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-gap"
          >
            <div className="border-solid border-[1px] border-white rounded-[50px] overflow-hidden">
              <div
                dangerouslySetInnerHTML={{
                  __html: `<video src='images/Sport.mp4' muted loop autoPlay playsInline className='w-full'></video>`,
                }}
              />
              <div className="p-[30px] flex justify-center flex-col">
                <h2 className="text-white text-[20px] lg:text-[30px] font-[400] font-medium">Sport</h2>
                <p className="text-white text-[10px] lg:text-[16px] font-[400] font-light leading-[20px]">Artica's covered running races and several other sports' events</p>
              </div>
            </div>
            <div className="border-solid border-[1px] border-white rounded-[50px] overflow-hidden">
              <div
                dangerouslySetInnerHTML={{
                  __html: `<video src='images/4to muelle.mp4' muted loop autoPlay playsInline className='w-full'></video>`,
                }}
              />
              <div className="p-[30px] flex justify-center flex-col">
                <h2 className="text-white text-[20px] lg:text-[30px] font-[400] font-medium">Entertainment</h2>
                <p className="text-white text-[10px] lg:text-[16px] font-[400] font-light leading-[20px]">From all angles, we record those unique moments of every business</p>
              </div>
            </div>
            <div className="border-solid border-[1px] border-white rounded-[50px] overflow-hidden">
              <div
                dangerouslySetInnerHTML={{
                  __html: `<video src='images/ccs.mp4' muted loop autoPlay playsInline className='w-full'></video>`,
                }}
              />
              <div className="p-[30px] flex justify-center flex-col">
                <h2 className="text-white text-[20px] lg:text-[30px] font-[400] font-medium">Lanscapes</h2>
                <p className="text-white text-[10px] lg:text-[16px] font-[400] font-light leading-[20px]">We love nature and its immensity. <br />Greater vision scopes take our breath away!</p>
              </div>
            </div>
          </Carousel>
        </div>      
      </div>
    </section>
  )
}

export default Portfolio