import React from "react";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <div className="px-[28px] md:px-[54px] lg:px-[125px] mb-[190px]">
      <div className="w-full">
        <img className="w-full" src="images/old.jpg" alt="old" />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 mt-[80px] lg:mt-[135px]">
        <div className="pt-[50px]">
          <h1 className="font-medium text-[#212121] font-[700] text-[40px] lg:text-[50px] leading-[.9em]">GET IN TOUCH <br />WITH <br />ARTICA GROUP</h1>
          <h1 className="font-medium text-[#212121] font-[700] text-[50px] leading-[.9em]">_</h1>
          <p className="font-medium text--[#212121] font-[700] text-[19px] leading-[1.15789em] mt-[38px]">FILL OUT YOUR CONTACT DETAILS TO HEAR BACK FROM US</p>
        </div>
        <form className="flex flex-col mt-[30px] lg:mt-0">
          <input className="w-full border-b-[1px] border-b-[#212121] text-[#4e4e4e] ring-0 outline-none text-[18px] placeholder:text-[#212121] px-[20px] py-[9.5px] pl-0 pointer leading-[27px]" required placeholder="WHAT'S YOUR NAME?*" />
          <input className="w-full mt-[30px] border-b-[1px] border-b-[#212121] text-[#4e4e4e] ring-0 outline-none text-[18px] placeholder:text-[#212121] px-[20px] py-[9.5px] pl-0 pointer leading-[27px]" required placeholder="YOUR BEST EMAIL*" />
          <input className="w-full mt-[30px] border-b-[1px] border-b-[#212121] text-[#4e4e4e] ring-0 outline-none text-[18px] placeholder:text-[#212121] px-[20px] py-[9.5px] pl-0 pointer leading-[27px]" required placeholder="PHONE NUMBER*" />
          <input className="w-full mt-[30px] border-b-[1px] border-b-[#212121] text-[#4e4e4e] ring-0 outline-none text-[18px] placeholder:text-[#212121] px-[20px] py-[9.5px] pl-0 pointer leading-[27px]" required placeholder="WHAT DO YOU NEED FROM ARTICA?*" />
          <div className="flex mt-[30px]">
            <input type='checkbox' />
            <p className="ml-[10px] font-light text-[#212121] text-[16px]">I read and hereby accept the <Link to='/legal' className="cursor-pointer underline">legal notice and privacy policies</Link></p>
          </div>          
          <button type="submit" className="mt-[20px] w-fit underline font-medium text-[#212121] text-[15px] font-[700]" >SEND</button>
        </form>
      </div>
    </div>
  )
}

export default Contact